import { LegacyAny } from '@soracom/shared/core';

import { OperatorConfigurationService } from '@soracom/shared-ng/operator-configuration';
import { OperatorConfiguration } from '@soracom/shared/soracom-platform';
import { UCStorage } from '../../../src/app/shared/UCStorage';
import { BillingsService } from '../billings/billings.service';
import { InjectList } from '../core/injectable';

import { Subject, takeUntil } from 'rxjs';

// Obviously, this name is temporary 😇
import {
  ApiCredentialsService,
  LoginEvent,
  AuthService as SexyDynamiteAuthService,
  TokenExpirationWatcher,
  getLoginUserData,
} from '@soracom/shared/data-access-auth';
import { LoginUserDataService } from '@soracom/shared/data-access-auth';
import { LogoutHousekeepingResult } from '@soracom/shared-ng/ui-logout-utils';
import { $locationShim } from '@angular/common/upgrade';
import { assertNever } from '@soracom/shared/core';
import { environment } from 'apps/user-console/src/environments/environment';

export class AuthService {
  static $inject: InjectList = [
    '$location',
    '$rootScope',
    'BillingsService',
    'OperatorConfigurationService',
    'SexyDynamiteAuthService',
    'LoginUserDataService',
    'ApiCredentialsService',
    'TokenExpirationWatcher',
  ];

  /**
   * Flag used to avoid infinite loop when the user is logged out due to external events.
   */
  logoutInProgress = false;

  private destroy$ = new Subject<void>();

  constructor(
    private $location: $locationShim,
    private $rootScope: LegacyAny,
    private billingsService: BillingsService,
    private operatorConfigurationService: OperatorConfigurationService,
    public sexyDynamiteAuthService: SexyDynamiteAuthService,
    public loginUserDataService: LoginUserDataService,
    public apiCredentialsService: ApiCredentialsService,
    public tokenExpirationWatcher: TokenExpirationWatcher,
  ) {
    // This initial stuff is a workaround for a major [bug](https://app.shortcut.com/soracom/story/89889/header-shows-logged-in-state-even-when-logged-out-if-certain-localstorage-values-exist) in production:
    const loggedInUser = this.loginUserDataService.getLoginUser();
    if (loggedInUser) {
      const lastLoginEvent = this.sexyDynamiteAuthService.lastLoginEvent;
      if (!lastLoginEvent) {
        console.error('PANIC: Missing lastLoginEvent');
        this.logout();
      }
      // @ts-expect-error (legacy code incremental fix)
      this.doPostLoginHousekeeping(lastLoginEvent);
    } else {
      this.removeLocalStorageCredentials();
    }
    // END of workaround

    this.sexyDynamiteAuthService.authEvent$.pipe(takeUntil(this.destroy$)).subscribe((authEvent) => {
      const isLoggedIn = this.sexyDynamiteAuthService.isLoggedIn();
      if (!isLoggedIn) {
        return;
      }

      switch (authEvent.type) {
        case 'login':
          this.doPostLoginHousekeeping(authEvent);
          break;
        case 'logout':
          break;
        case 'token':
          // This set performs the side effects needed by various legacy entities (for now)
          break;
        case 'expired':
          break;
        default:
          assertNever(authEvent);
      }
    });
  }

  /**
   * 😅 I am still not convinced wrt rxjs... this destroy() isn't really necessary in a service that will live forever, but... felt weird not to add the capability
   */
  destroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * This is a new implementation in the legacy AuthService, which aims to do ALL the logout housekeeping in one place.
   *
   * This will be called back from the new /logout page. That page will await this method, and block the UI with a progress screen, so any kind of async housekeeping can be done here. The point is, this method must be able to do ALL the housekeeping that the old legacy logout process did. That isn't 100% obvious, though, because a lot of it was happening as weird side effects of other things.
   */
  async handleLogout(): Promise<LogoutHousekeepingResult> {
    try {
      const queryParams = this.$location.search() as any;

      // remove any credentials that we may have saved above before the error occurred
      this.removeLocalStorageCredentials();

      this.operatorConfigurationService.operatorConfiguration = undefined;
      this.$rootScope.$broadcast('logout');

      let redirectUrl = `${environment.authSSOUrl}/login`;
      try {
        const { isRootUser } = getLoginUserData(); // throws an error if there is no user login data.
        if (!isRootUser) {
          redirectUrl += '/samlogin';
        }
      } catch (e) {
        // If there is no user login data, an error will be thrown, but ignore it. We'll anyway redirect to the login page below. (Fixes sc-106243.)
      }

      await this.sexyDynamiteAuthService.logout();

      return { redirectUrl, queryParams };
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : `${error}`;
      return { error: errorMessage };
    }
  }

  /**
   * Re-implementation of the legacy `doPostLoginHousekeeping()`, but it tries to achieve the same thing without relying on input parameters (instead, it uses the new core services to obtain the info).
   *
   * Unlike the old implementation, this is not called directly, but instead relies on the new `authEvent$` observable provided by the new `SexyDynamiteAuthService`. UPDATE: There was a bug, due to this class being initialized too late to see the first event, so it is now manually called in the constructor too.
   */
  private doPostLoginHousekeeping(event: LoginEvent) {
    const currentUser = event;
    let email: string | null = null;

    if (currentUser.isRootAccount === true) {
      email = currentUser.email;
    }

    // @ts-expect-error (legacy code incremental fix)
    UCStorage.loginEmail = email;

    this.billingsService.checkPaymentError();

    // For now, the AuthService is responsible for actually setting the operator configuration, since we get it from the auth API.
    const operatorConfig = new OperatorConfiguration((event as any)?.configuration);
    this.operatorConfigurationService.operatorConfiguration = operatorConfig;
  }

  /**
   * Remove localStorage credentials. This is done both on logout and on any error that happens during authentication.
   */
  private removeLocalStorageCredentials() {
    // @ts-expect-error (legacy code incremental fix)
    UCStorage.loginEmail = null;
  }

  /**
   * NOTE: This WAS the canonical logout method. It is called by forceLogout() and it calls logoutSSO() at the end. So logoutSSO() is probably the legacy equivalent of the new AuthService's logout() method.
   *
   * NOW it does NONE of that. It is responsible only for capturing the return_to URL, and then redirecting to the new logout page. All cleanup is handled by the /logout page. However, that currently works by calling back to this class's handleLogout() method. That makes sense for now, but as we unpack all that housekeeping logic in additional incremental PRs, then this class will be deleted and that housekeeping logic will be done elsewhere.
   *
   * In Angular, you can just do like router.navigate(['logout']).then(() => /* ... * /) and handle the logout cleanup without a callback. But in this case we are still using $location to navigate so we use the callback way.
   */
  async logout() {
    this.logoutInProgress = true;

    let returnTo: string;
    const operatorId = getLoginUserData().operatorId;

    const url = this.$location.url();
    if (url === '/') {
      // @ts-expect-error (legacy code incremental fix)
      returnTo = undefined; // This is default value, so we can omit it
    } else {
      returnTo = url;
    }

    this.logoutInProgress = false;

    if (UCStorage.loginAsRoot) {
      this.$location.url('/logout').search('return_to', returnTo);
    } else {
      this.$location.url('/logout').search({ o: operatorId, return_to: returnTo });
    }
    this.$location;

    this.logoutInProgress = false;
  }
}
